import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {take} from 'rxjs/operators'
import {ToastrService} from 'ngx-toastr'
import {Apollo} from 'apollo-angular'
import {Router} from '@angular/router'

/**
 * This service is a singleton providing authentication status
 */
@Injectable({
  providedIn: 'root',
})
export class AuthStatusService {
  private readonly _authenticatedUser$ = new BehaviorSubject<AuthenticatedUserWithStatus>({
    isLoading: true,
    authenticatedUser: undefined,
  })
  public readonly authenticatedUser$: Observable<AuthenticatedUserWithStatus> = this._authenticatedUser$.asObservable()

  private proTakeOver: {id: string; companyName: string; role: ProRole} | undefined
  private agentTakeOver: {id: string} | undefined
  private clientTakeOver: {id: string} | undefined

  constructor(
    private readonly toastr: ToastrService,
    private readonly apollo: Apollo,
    private readonly router: Router,
  ) {}

  sendAuthStatusUpdate(status: AuthenticatedUserWithStatus): void {
    let update = status

    // because we can have multiple AuthService, we may get status updates during a take over, this makes sure to add the pro take over to the current user
    if (this.proTakeOver && update.authenticatedUser) {
      update = {
        ...update,
        authenticatedUser: {
          ...update.authenticatedUser,
          pro: {id: this.proTakeOver.id, role: this.proTakeOver.role},
        },
      }
    }

    if (this.agentTakeOver && update.authenticatedUser) {
      update = {
        ...update,
        authenticatedUser: {
          ...update.authenticatedUser,
          agent: {id: this.agentTakeOver.id},
        },
      }
    }

    if (this.clientTakeOver && update.authenticatedUser) {
      update = {
        ...update,
        authenticatedUser: {
          ...update.authenticatedUser,
          client: {id: this.clientTakeOver.id},
        },
      }
    }

    this._authenticatedUser$.next(update)
  }

  /**
   * Returns if the user is signed in at this particular moment
   * @warn this is not reliable enough to get the user status, use the observable `authenticatedUser$` instead
   */
  isSignedIn(): boolean {
    const user = this._authenticatedUser$.getValue()
    return !!user?.authenticatedUser
  }

  me(): {userId: string; firstName: string; lastName: string; isPro: boolean; isClient: boolean} | undefined {
    const user = this._authenticatedUser$.getValue()
    return user?.authenticatedUser
      ? {
          userId: user.authenticatedUser.userId,
          firstName: user.authenticatedUser.firstName,
          lastName: user.authenticatedUser.lastName,
          isPro: !!user.authenticatedUser.pro,
          isClient: !!user.authenticatedUser.client,
        }
      : undefined
  }

  getUserEmail(): string | undefined {
    const user = this._authenticatedUser$.getValue()
    return user?.authenticatedUser ? user.authenticatedUser.email : undefined
  }

  isCurrentUserAdmin(): boolean {
    const user = this._authenticatedUser$.getValue()
    return !!user?.authenticatedUser?.isGlobalAdministrator
  }

  async takeProRole(pro: {id: string; companyName: string; role: ProRole}) {
    const returnUrl = this.router.url
    await this.cancelRoleTakeover()
    this.proTakeOver = pro
    this.sendAuthStatusUpdate(this._authenticatedUser$.value)
    this.toastr
      .warning(`You have taken over role '${pro.role}' for pro '${pro.companyName}'. Click here to stop.`, undefined, {
        disableTimeOut: true,
        tapToDismiss: true,
        positionClass: 'toast-bottom-right',
      })
      .onTap.pipe(take(1))
      .subscribe(() => this.cancelRoleTakeover(() => this.router.navigateByUrl(returnUrl)))
  }

  async takeAgentRole(agent: {id: string}) {
    const returnUrl = this.router.url
    await this.cancelRoleTakeover()
    this.agentTakeOver = agent
    this.sendAuthStatusUpdate(this._authenticatedUser$.value)
    this.toastr
      .warning(`You have taken over agent account '${agent.id}'. Click here to stop.`, undefined, {
        disableTimeOut: true,
        tapToDismiss: true,
        positionClass: 'toast-bottom-right',
      })
      .onTap.pipe(take(1))
      .subscribe(() => this.cancelRoleTakeover(() => this.router.navigateByUrl(returnUrl)))
  }

  async takeClientRole(client: {id: string; firstName: string; lastName: string}) {
    const returnUrl = this.router.url
    await this.cancelRoleTakeover()
    this.clientTakeOver = client
    this.sendAuthStatusUpdate(this._authenticatedUser$.value)
    this.toastr
      .warning(
        `You have taken over client account of '${client.firstName} ${client.lastName}'. Click here to stop.`,
        undefined,
        {
          disableTimeOut: true,
          tapToDismiss: true,
          positionClass: 'toast-bottom-right',
        },
      )
      .onTap.pipe(take(1))
      .subscribe(() => this.cancelRoleTakeover(() => this.router.navigateByUrl(returnUrl)))
  }

  private async cancelRoleTakeover(done?: () => Promise<boolean>) {
    this.toastr.clear()
    this.proTakeOver = undefined
    this.agentTakeOver = undefined
    this.clientTakeOver = undefined
    const current = this._authenticatedUser$.value
    const update: AuthenticatedUserWithStatus = {
      ...current,
      authenticatedUser: {
        ...(current.authenticatedUser as AuthenticatedUser),
        pro: undefined,
        agent: undefined,
        client: undefined,
      },
    }
    await this.apollo.client.resetStore()
    this.sendAuthStatusUpdate(update)
    if (done) {
      return done()
    }
  }
}

export interface AuthenticatedUserWithStatus {
  isLoading: boolean
  authenticatedUser?: AuthenticatedUser
}

export interface AuthenticatedUser {
  /** ID from the DB, not cognito */
  userId: string
  firstName: string
  lastName: string
  email: string
  locale: string
  phoneNumber: string
  cognitoUsername: string
  isGlobalAdministrator: boolean
  pro?: ProContext
  agent?: {
    id: string
  }
  client?: {
    id: string
  }
}

export interface ProContext {
  id: string
  role: ProRole
}

export type ProRole = 'executor' | 'pro_admin'
export type AdminRole = 'global_admin'
