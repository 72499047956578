import {HttpHeaders} from '@angular/common/http'
import {preventBgoProprietaryHttpInterceptorHeader} from '../http/prevent.interceptor'
import {ApolloLink} from '@apollo/client/core'
import {HttpLink} from 'apollo-angular/http'
import {ConfigService} from '../config/config.service'
import {Kind, OperationTypeNode, print, stripIgnoredCharacters} from 'graphql'
import {CMS_GRAPHQL_CLIENT_NAME} from '../content/content-graphql-split'
import {setContext} from '@apollo/client/link/context'
import {Injectable} from '@angular/core'

import {getMainDefinition} from '@apollo/client/utilities'
import {Logger} from '../logging/logger.types'
import {I18nService} from '../../craft/i18n/i18n.service'

@Injectable({providedIn: 'root'})
export class GraphqlLinkFactory {
  constructor(
    private readonly configService: ConfigService,
    private readonly httpLink: HttpLink,
    private readonly logger: Logger,
    private readonly i18n: I18nService,
  ) {}

  build(getJwtToken?: () => Promise<string | undefined>): ApolloLink {
    const auth = this.authLink(getJwtToken)
    return this._build(auth)
  }

  async buildWithPubSup(getJwtToken?: () => Promise<string | undefined>): Promise<ApolloLink> {
    const authLink = this.authLink(getJwtToken)
    return this._build(authLink, await this.pubSubApiLink(getJwtToken))
  }

  private _build(auth?: ApolloLink, pubSubApi?: ApolloLink): ApolloLink {
    const datoCmsApi = this.datoCmsApiLink()
    const coreApi = this.coreGraphqlApiLink(auth)

    // the pubsub API with real time capabilities is separated from the core API
    // split traffic to our backend API between the pubsub endpoint (with websockets) and the core api
    const buildigoApi = pubSubApi
      ? ApolloLink.split(
          ({query}) => {
            const definition = getMainDefinition(query)
            return (
              definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION
            )
          },
          pubSubApi,
          coreApi,
        )
      : coreApi

    // split traffic between CMS and our backend API depending on the client name
    return ApolloLink.split(
      op => [CMS_GRAPHQL_CLIENT_NAME].includes(op.getContext().clientName),
      datoCmsApi,
      buildigoApi,
    )
  }

  private authLink(getJwtToken?: () => Promise<string | undefined>) {
    if (!getJwtToken) {
      return undefined
    }
    return setContext(() => withAuthHeaders(getJwtToken))
  }

  private coreGraphqlApiLink(authLink?: ApolloLink): ApolloLink {
    const buildigoApi = this.httpLink.create({
      uri: this.configService.config.apiUrl,
      headers: new HttpHeaders({
        // manually set these headers for this link, because other links may not accept these headers in their CORS policy
        'apollographql-client-name': 'bgo-web-ui',
        'apollographql-client-version': this.configService.config.version,
      }),
      // override default behavior (print) to strip out unnecessary characters, makes queries smaller
      operationPrinter: documentNode => stripIgnoredCharacters(print(documentNode)),
    })
    let buildigoLink: ApolloLink[] = [buildigoApi]
    if (authLink) {
      buildigoLink = [authLink, buildigoApi]
    }

    return ApolloLink.from(buildigoLink)
  }

  private async pubSubApiLink(getJwtToken?: () => Promise<string | undefined>) {
    if (!getJwtToken) {
      return undefined
    }

    const pubSubApiUrl = this.configService.config.pubSubApiUrl
    if (!pubSubApiUrl) {
      this.logger.warn(`No pubsub config found!`)
      return undefined
    }

    const {GraphQLWsLink} = await import('@apollo/client/link/subscriptions')
    const {createClient} = await import('graphql-ws')

    return new GraphQLWsLink(
      createClient({
        url: pubSubApiUrl,
        shouldRetry: () => true,
        connectionParams: {
          authToken: await getJwtToken(),
          language: this.i18n.getActiveLang(),
        },
      }),
    )
  }

  private datoCmsApiLink() {
    const {apiToken, preview, environment} = this.configService.config.datocms
    return this.httpLink.create({
      uri: `https://graphql.datocms.com/`,
      headers: new HttpHeaders({
        ...preventBgoProprietaryHttpInterceptorHeader,
        Authorization: `Bearer ${apiToken}`,
        ...(preview ? {'X-Include-Drafts': 'true'} : undefined),
        ...(environment ? {'X-Environment': environment} : undefined),
      }),
      // override default behavior (print) to strip out unnecessary characters, makes queries smaller
      // this helps with APIs query size limit
      // https://www.contentful.com/developers/docs/references/graphql/#/introduction/query-size-limits
      // https://www.apollographql.com/docs/react/networking/advanced-http-networking/
      operationPrinter: documentNode => stripIgnoredCharacters(print(documentNode)),
    })
  }
}

export async function withAuthHeaders(getJwtToken: () => Promise<string | undefined>) {
  const bearerToken = await getJwtToken()
  if (bearerToken) {
    return {headers: {Authorization: 'Bearer ' + bearerToken}}
  } else {
    return {}
  }
}
